import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme) =>
  createStyles({
    popoverRoot: {
      minWidth: 220,
      minHeight: 120,
      overflow: 'initial',
      boxShadow:
        '0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05), 0 -1px 5px 0 rgba(0,0,0,0.05);',
      borderRadius: 4,
    },
    headerLabel: {
      color: '#000',
      padding: '10px 15px',
    },
    headerButton: {
      float: 'right',
      position: 'absolute',
      right: 0,
      color: 'purple',
    },
    popoverHeader: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #eee',
      height: 50,
      display: 'flex',
      alignItems: 'center',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
  });

export type Styles = WithStyles<typeof styles>;
