import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      minHeight: 120,
      minWidth: 220,
      borderRadius: 4,
      boxShadow:
        '0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05), 0 -1px 5px 0 rgba(0,0,0,0.05);',
      display: 'flex',
      flexDirection: 'column',
    },
  });

export type Styles = Partial<WithStyles<typeof styles>>;
