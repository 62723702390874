import React, {FunctionComponent, HTMLAttributes} from 'react';

import IconButton from '@material-ui/core/IconButton';
import withStyles, {ClassNameMap} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';

import {useWindowDimensions} from '../../../../hooks/useWindowDimensions';
import {toOption} from '../../../../utils/array';
import {numberWithCommas} from '../../../../utils/number';
import {Autocomplete, AutocompleteSize} from '../../../Autocomplete';
import Tabs from '../../../Tabs/Tabs';
import {Styles, styles} from './Footer.style';
import {PageSizeControl, PageSizeControlProps} from './PageSizeControl';

export type DatagridFooterProps = Styles &
  HTMLAttributes<HTMLDivElement> & {
    pageSizeOptions?: PageSizeControlProps['sizeOptions'];
    pageSize?: PageSizeControlProps['size'];
    skip: number;
    total: number;
    onSkipChange: (skip: number) => void;
    onPageSizeChange: PageSizeControlProps['onChange'];
    tabs?: string[];
    onTabChange?: (tab: string) => void;
    selectedTab?: string;
  };

type Classes = ClassNameMap<'footerRoot'>;

type FooterTabProps = {
  tabs: string[];
  onTabChange: (tab: string) => void;
  selectedTab: string;
  classes: Classes;
};

const FooterTab: FunctionComponent<FooterTabProps> = ({
  tabs,
  onTabChange,
  selectedTab,
  classes,
}) => {
  const PAGINATION_SECTION_WIDTH = 360;
  const {width} = useWindowDimensions();

  const tabOptions = tabs.map((tab) => ({value: tab, label: tab, disabled: false}));
  const maxLength = tabs.reduce((acc, curr) => acc + Math.min(curr.length * 8 + 30, 264), 0);
  const tabSectionIsTooWide = maxLength > width - PAGINATION_SECTION_WIDTH;

  const handleSelectedTab = (ev, tab: string) => {
    onTabChange(tab);
  };

  return tabSectionIsTooWide ? (
    <Autocomplete
      options={tabOptions}
      value={toOption(selectedTab)}
      size={AutocompleteSize.small}
      onChange={(ev) => {
        handleSelectedTab(ev, ev.value);
      }}
      classes={{root: classes.footerRoot}}
    />
  ) : (
    <Tabs
      tabs={tabOptions}
      onChange={handleSelectedTab}
      selectedTab={selectedTab}
      indicatorPosition="top"
    />
  );
};

const DatagridFooter: FunctionComponent<DatagridFooterProps> = ({
  classes,
  className,
  pageSize,
  pageSizeOptions,
  skip,
  total,
  onSkipChange,
  onPageSizeChange,
  onTabChange,
  tabs,
  selectedTab,
}) => {
  const handleSkipChange = (updatedSkip: number) => () => onSkipChange(updatedSkip);
  const caption = React.useMemo(() => {
    const hasMoreThan1Page = (pageSizeOptions || []).length > 1;
    return total ? (hasMoreThan1Page ? 'Rows per page' : '') : 'Results not found';
  }, [total, pageSizeOptions]);
  const showTabs = tabs && tabs.length > 1;
  return (
    <div className={classNames(classes.root, {[classes.multipleSections]: showTabs})}>
      {showTabs && (
        <div className={classes.tabsSection}>
          <FooterTab
            tabs={tabs}
            onTabChange={onTabChange}
            selectedTab={selectedTab}
            classes={classes}
          />
        </div>
      )}
      <div className={classNames(classes.pagingSection, className)}>
        {caption && (
          <Typography className={classes.typography} variant="caption">
            {caption}
          </Typography>
        )}
        {!!total && (
          <>
            {pageSizeOptions.length > 1 && (
              <PageSizeControl
                size={pageSize}
                sizeOptions={pageSizeOptions}
                onChange={onPageSizeChange}
              />
            )}

            <Typography className={classes.typography} variant="caption">
              {numberWithCommas(skip + 1)} - {numberWithCommas(getPageSize(pageSize, skip, total))}
              &nbsp;of&nbsp;
              <span>{numberWithCommas(total)}</span>
            </Typography>

            <IconButton
              type="button"
              style={{marginRight: '0.5rem'}}
              onClick={handleSkipChange(skip - pageSize)}
              disabled={skip - pageSize < 0}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              type="button"
              onClick={handleSkipChange(skip + pageSize)}
              disabled={skip + pageSize + 1 > total}
            >
              <ChevronRight />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

DatagridFooter.defaultProps = {
  pageSize: 50,
  pageSizeOptions: [50, 100, 200],
};

export default withStyles(styles)(DatagridFooter);

const getPageSize = (pageSize: number, skip: number, total: number) => {
  if (skip + pageSize > total) {
    return total;
  }

  return pageSize + skip;
};
