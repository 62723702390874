import React, {FunctionComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {currentYear} from '../../../../utils/date';
import {Styles, styles} from './Footer.style';
import {FooterLink} from './Link';

export type DrawerFooterProps = Styles & {
  children?: string;
};

const Presentational: FunctionComponent<DrawerFooterProps> = ({classes, children}) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.message}>{children}</Typography>
      <Typography className={classes.linksContent}>
        <FooterLink
          href="https://www.outfrontmedia.com/pages/privacyhighlights.aspx"
          title="Read the Privacy Policy"
        >
          Privacy Policy
        </FooterLink>
        <FooterLink
          href="https://www.outfrontmedia.com/pages/termsofuse.aspx"
          title="Read the Terms of Use"
        >
          Terms of Use
        </FooterLink>
      </Typography>
    </div>
  );
};
Presentational.defaultProps = {
  children: `© ${currentYear} OUTFRONT Media, Inc.`,
};

export default withStyles(styles)(Presentational);
